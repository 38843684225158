export const DIR_RTL = "rtl";
export const DIR_LTR = "ltr";
export const MODE_LIGHT = "light";
export const MODE_DARK = "dark";
export const LAYOUT_TYPE_CLASSIC = "classic";
export const LAYOUT_TYPE_MODERN = "modern";
export const LAYOUT_TYPE_STACKED_SIDE = "stackedSide";
export const LAYOUT_TYPE_DECKED = "decked";
export const LAYOUT_TYPE_SIMPLE = "simple";
export const LAYOUT_TYPE_BLANK = "blank";
export const NAV_MODE_LIGHT = "light";
export const NAV_MODE_DARK = "dark";
export const NAV_MODE_THEMED = "themed";
export const NAV_MODE_TRANSPARENT = "transparent";

export const SIDE_NAV_WIDTH = 290;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const SPLITTED_SIDE_NAV_MINI_WIDTH = 80;
export const SPLITTED_SIDE_NAV_SECONDARY_WIDTH = 250;
export const SIDE_NAV_CONTENT_GUTTER = "px-4";
export const LOGO_X_GUTTER = "px-6";
export const HEADER_HEIGHT_CLASS = "h-16";
export const PAGE_CONTAINER_GUTTER_X = "px-4 sm:px-6 md:px-8";
export const PAGE_CONTAINER_GUTTER_Y = "py-4 sm:py-6 md:px-8";

export const CONFIRM_BUTTON_COLOR = "blue-600";

export const THEME_ENUM = {
  DIR_RTL: DIR_RTL,
  DIR_LTR: DIR_LTR,
  MODE_LIGHT: MODE_LIGHT,
  MODE_DARK: MODE_DARK,
  LAYOUT_TYPE_CLASSIC: LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN: LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE: LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE: LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED: LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK: LAYOUT_TYPE_BLANK,
  SIDE_NAV_WIDTH: SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH: SIDE_NAV_COLLAPSED_WIDTH,
  SPLITTED_SIDE_NAV_MINI_WIDTH: SPLITTED_SIDE_NAV_MINI_WIDTH,
  SPLITTED_SIDE_NAV_SECONDARY_WIDTH: SPLITTED_SIDE_NAV_SECONDARY_WIDTH,
  HEADER_HEIGHT_CLASS: HEADER_HEIGHT_CLASS,
  NAV_MODE_LIGHT: NAV_MODE_LIGHT,
  NAV_MODE_DARK: NAV_MODE_DARK,
  NAV_MODE_THEMED: NAV_MODE_THEMED,
  NAV_MODE_TRANSPARENT: NAV_MODE_TRANSPARENT,
};

export const BRAND_NAME = "Quamus LMS";
export const COPYRIGHT_BRAND = "PT Quamus Global Academy";

export const PRIMARY_COLOR = "#0099CC";
export const PRIMARY_COLOR2 = "#A7F8C9";
export const GRAY = "#999999";
export const BLUE = "#006699";
export const BACKGROUND_PAGE = "#f6f8fa";

export const BLUE_100 = "#dbeafe";
export const GREEN_LOGOUT = "#009900";

export const SIDEBAR_WIDTH_OPEN = 265;
export const SIDEBAR_WIDTH_CLOSE = 75;
export const SIDEBAR_BUTTON_ICON_SIZE = 18;
export const SIDEBAR_BUTTON_ICON_COLOR = "#0099CC";
export const SIDEBAR_BUTTON_ICON_COLOR_SOON = "#999999";
export const SIDEBAR_BUTTON_COLOR = PRIMARY_COLOR;
export const SIDEBAR_COLOR = "#FFFFFF";
export const NAVBAR_HEIGHT = 70;
export const FOOTER_HEIGHT = 60;
export const TOAST_TIME = 2000;
export const COURSEWARE_TAB_HEIGHT = 62;

export const PREMIUM_W = 30;
export const NAVBAR_LOGIN_HEIGHT = 60;
export const BREADCRUMB_HEIGHT = 70;

export const TABLE_CELL_PADDING_X = 10;
export const TABLE_CELL_PADDING_Y = 10;
export const TABLE_CELL_BG_STRIP_COLOR = "#F9F9F9";
export const TABLE_CELL_BG_ACTIVE_COLOR = "#FFFFCC";
export const TABLE_HEADER_BG_COLOR = PRIMARY_COLOR;
export const TABLE_HEADER_BG_COLOR2 = "#F1F1F1";
export const TABLE_STROKE_LINE_COLOR = "#CCCCCC";
export const TABLE_PAGINATION_POSITION = "center";
export const BORDER_COLOR = "#eeeeee";

export const ROLE_COOKIES = "vG3WV9B78Zr2GrDgsRtVNMfjP";
export const SECRET_KEY = "zZCMxbyqteK5ZknWAC6dmZEht7bU7AZA2dWp6EYQ";
export const JWT_ACCESS_COOKIES = "cZVQyBJpcu8Sbxkbed8WhsC3w";
export const AUTH_PREFIX = "JWT";

export const TINYMCE_API_KEY =
  "x2381xcs62b0lfz5klvf482iewutqxkj4bvp5n1epl1gc2vj";
export const TINY_MCE_UPLOAD_JWT_ENDPOINT =
  "https://api.quamuslms.id/school/teacher_activity/upload";
export const TINY_MCE_UPLOAD_ENDPOINT =
  "https://api.quamuslms.id/school/teacher_activity/demo_upload";
export const YOUTUBE_URL =
  "https://www.youtube-nocookie.com/embed/{url}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1&fs=1";

export const TEMPLATE_DATA_GURU =
  "https://quamus-lms-s3.s3.ap-southeast-3.amazonaws.com/web-assets/Template+Data+Guru.xlsx";

export const TEMPLATE_DATA_SISWA =
  "https://quamus-lms-s3.s3.ap-southeast-3.amazonaws.com/web-assets/Template+Data+Siswa2.xlsx";

export const TEMPLATE_SISWA_REGULER =
  "https://quamus-lms-s3.s3.ap-southeast-3.amazonaws.com/quran/import_template/TemplateDataSiswa_tahfidz_reg_class.xlsx";

export const CBT_COOKIES = "mrc5znAx45kWHMJk93eRnx73P4xAuK";
export const DEFAULT_TRESHOLD = 60;
export const SET_TEST_COOKIES = "LhYRt9ry3nQVvaF6ZDwbESe";

export const PACKAGE = {
  QUAMUS_LMS: {
    BASIC: "Basic",
    PRO: "Pro",
    ULTIMATE: "Ultimate",
    ULTIMATE_PLUS: "Ultimate Plus",
  },
  AQLA_ACADEMY: {
    FREE: "Free",
    PREMIUM: "Premium",
    PREMIUM_PLUS: "Premium Plus",
  },
  RMI_LMS: {
    BASIC: "Basic",
    PRO: "Pro",
    ULTIMATE: "Ultimate",
    ULTIMATE_PLUS: "Ultimate Plus",
  },
};

export const ROW_CENTER = "flex flex-row justify-center items-center";
export const ROW_START = "flex flex-row justify-start items-center";
export const ROW_END = "flex flex-row justify-end items-center";
export const ROW_BETWEEN = "flex flex-row justify-between items-center";
export const COL_CENTER = "flex flex-col justify-center items-center";
export const COL_START = "flex flex-col justify-start items-center";
export const COL_BETWEEN = "flex flex-col justify-between items-center";

export const BLUE2 = "#00a4ff";
export const RED = "#Ff004a";
export const GREEN = "#159939";

export const ACTIVE_BORDER = "#00cccc";
export const INACTIVE_BORDER = "#94a3b8";

export const ARABIC_TOSCA = "#009999";

export const ICON_HEADER_SIZE = 30;

export const GRADIENT_COLOR = {
  "640a8a2a4a2262671d6c3acb": ["#C700C7", "#FFADD6"],
  "63be0e8b0fce905c08673dbe": ["#FF6600", "#FFCC00"],
  "638ed035f082db995350e719": ["#009BC2", "#8CEAEA"],
  "63be0e910fce905c08673dbf": ["#146A6A", "#99EAEA"],
};
export const ALFABET_SMALL = "abcdefghijklmnopqrstuvwxyz";
