import React from "react";
import PropTypes from "prop-types";
import useAuthority from "utils/hooks/useAuthority";
import { useSelector } from "react-redux";

const AuthorityComponent = (props) => {
  const userAuthority = useSelector((state) => state.auth.user.authority);
  const { authority = [], children } = props;

  const roleMatched = useAuthority(userAuthority, authority);

  return roleMatched ? children : <></>;
};

AuthorityComponent.propTypes = {
  userAuthority: PropTypes.array,
  authority: PropTypes.array,
};

export default AuthorityComponent;
