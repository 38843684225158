import React from 'react';
import Input from 'components/ui/Input';
import { NumericFormat } from 'react-number-format';

const InputAffix = {
    inputSuffix: null,
    inputPrefix: null,
};

const NumberInput = (props) => {
    const { inputSuffix, inputPrefix, ...rest } = props;
    return (
        <Input
            {...rest}
            value={props.value}
            suffix={inputSuffix}
            prefix={inputPrefix}
        />
    );
};

const NumberFormatInput = (props) => {
    const { onValueChange, form, field, ...rest } = props;
    return (
        <NumericFormat
            customInput={NumberInput}
            form={form}
            field={field}
            onBlur={field?.onBlur}
            onValueChange={onValueChange}
            {...rest}
        />
    );
};

const FormNumericInput = (props) => {
    const {
        form,
        field,
        inputSuffix,
        inputPrefix,
        onValueChange,
        disabled,
        ...rest
    } = props;
    return (
        <NumberFormatInput
            disabled={disabled}
            form={form}
            field={field}
            inputPrefix={inputPrefix}
            inputSuffix={inputSuffix}
            onValueChange={onValueChange}
            {...rest}
        />
    );
};

export default FormNumericInput;
