import appConfig from "configs/app.config";
import ApiService from "services/ApiService";

export default class Index {
  static async fetchData(options) {
    return ApiService.fetchData({
      url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
      ...options,
    });
  }

  static async login(data) {
    return this.fetchData({
      url: `${appConfig.servicePrefix[appConfig.stage].auth}/user/login`,
      method: "post",
      data,
    });
  }
}
