import appConfig from "configs/app.config";
import ApiService from "./ApiService";

const BASE_URL = `${appConfig.servicePrefix[appConfig.stage].auth}`;

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: `${
      appConfig.servicePrefix[appConfig.stage].auth
    }/user/login/dashboard`,
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/reset-password",
    method: "post",
    data,
  });
}

export async function apiGetUser(params) {
  return ApiService.fetchData({
    url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
    method: "get",
    params,
  });
}

export async function apiCreateUser(data) {
  return ApiService.fetchData({
    url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
    method: "post",
    data,
  });
}

export async function apiUpdateUser(data) {
  return ApiService.fetchData({
    url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
    method: "put",
    data,
  });
}

export async function apiDeleteUser(data) {
  return ApiService.fetchData({
    url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
    method: "delete",
    data,
  });
}

export async function apiCreateUserAuthority(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}/user/authority`,
    method: "post",
    data,
  });
}

export async function apiExportXlsxUser(params) {
  return ApiService.fetchData({
    url: `${BASE_URL}/user/xlsx`,
    method: "get",
    responseType: "blob",
    params,
  });
}

export async function apiGetAuthority(params) {
  return ApiService.fetchData({
    url: `${BASE_URL}/user/authority`,
    method: "get",
    params,
  });
}

// Company
const COMPANY = "company";
export async function apiGetCompany(params) {
  return ApiService.fetchData({
    url: `${BASE_URL}/${COMPANY}`,
    method: "get",
    params,
  });
}

export async function apiCreateCompany(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}/${COMPANY}`,
    method: "post",
    data,
  });
}

export async function apiUpdateCompany(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}/${COMPANY}`,
    method: "put",
    data,
  });
}

export async function apiDeleteCompany(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}/${COMPANY}`,
    method: "delete",
    data,
  });
}

const FILE = "file";
export async function apiCreateFile(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}/${FILE}`,
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function apiGetNotification(params) {
  return ApiService.fetchData({
    url: `${appConfig.servicePrefix[appConfig.stage].auth}/notification`,
    method: "get",
    params,
  });
}
