import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        list: [],
        total: 0
    },
    reducers: {
        setNotification: (state, action) => {
            state.list = action.payload
            state.total = action.payload.length
        },
    },
})

export const { setNotification } =
    notificationSlice.actions

export default notificationSlice.reducer
