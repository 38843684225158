import { toast, Notification } from 'components/ui'
import { capitalizeFirstLetter } from 'utils/utils'

export default function PopNotification(type, status, message) {
  toast.push(
    <Notification
      title={`${capitalizeFirstLetter(status)}`}
      type={`${type}`}
      duration={2500}
    >
      {message}
    </Notification>, { placement: 'bottom-center', }
  )
}