import { PERSIST_STORE_NAME } from "constants/app.constant";
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
// import storage from 'redux-persist/lib/storage'
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "js-cookie";
const expireReducer = require("redux-persist-expire");

const middlewares = [];

const cookieStorageOptions = {
  expiration: {
    default: 3600 * 24 * 7, // Expire cookies after 24 hours (in seconds)
  },
  path: "/",
  // domain: "example.com", // Set the domain if needed
  // secure: true, // Set to true if using HTTPS
};

const persistConfig = {
  key: PERSIST_STORE_NAME,
  keyPrefix: "",
  // storage: storage,
  storage: new CookieStorage(Cookies, cookieStorageOptions),
  whitelist: ["auth", "locale"],
  transforms: [
    // expireReducer("auth", {
    //   expireSeconds: 3600 * 24 * 7, // Expire auth state after 24 hours
    //   autoExpire: true, // Ensure the state expires automatically
    // }),
    // expireReducer("locale", {
    //   expireSeconds: 3600 * 24 * 7, // Expire auth state after 24 hours
    //   autoExpire: true, // Ensure the state expires automatically
    // }),
  ],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer()),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

store.asyncReducers = {};

export const persistor = persistStore(store);

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(
    persistReducer(persistConfig, rootReducer(store.asyncReducers))
  );
  persistor.persist();
  return store;
};

export default store;
